import * as styles from "./employeeStepTwo.module.scss";
import {
  Button,
  FormControlLabel,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { FC } from "react";
import muiStyles from "../muiStylesEmployeeOnboarding";
import { muiStylesFormControlLabel as muiStylesFormControlLabel } from "../../../CompanySettings/muiStylesCompanySettings";
import { styleTextField as muiStylesTextField } from "../../../../styles/mui/styleFormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import { selectOnboardingUser } from "../../../../redux/selectors/onboardingSelectors";
import { employeeOnboardingStep2 } from "../../../../redux/actions/onboardingActions";
import { muiStylesButtons } from "../../../CompanySettings/muiStylesCompanySettings";

const useButtonStyles = makeStyles(muiStylesButtons);
const useStylesInput = makeStyles(muiStylesFormControlLabel);
const useTextFieldStyles = makeStyles(muiStylesTextField);
const useStyles = makeStyles(muiStyles);

const EmployeeStepTwo: FC = () => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const inputClasses = useStylesInput();
  const textFieldClasses = useTextFieldStyles();

  const dispatch = useDispatch();
  const user = useSelector(selectOnboardingUser);

  const handleNextStep = () => {
    dispatch(employeeOnboardingStep2());
  };

  return (
    <div>
      <h3>Let’s get started</h3>
      <Typography className={styles.paragraph}>
        Make sure your information looks good. If you seen any errors, please
        contact your admin at{" "}
        <span className={styles.adminEmail}>admin@motko.co</span>
      </Typography>
      <div>
        <div>
          <FormControlLabel
            classes={inputClasses}
            className={classes.leftInput}
            control={
              <TextField
                disabled
                fullWidth
                value={user.firstName}
                variant="filled"
                InputProps={{ className: textFieldClasses.textField }}
              />
            }
            label={"FIRST NAME"}
            labelPlacement="top"
          />

          <FormControlLabel
            classes={inputClasses}
            className={classes.rightInput}
            disabled
            control={
              <TextField
                disabled
                fullWidth
                value={user.lastName}
                variant="filled"
                InputProps={{ className: textFieldClasses.textField }}
              />
            }
            label={"LAST NAME"}
            labelPlacement="top"
          />
        </div>

        <FormControlLabel
          classes={inputClasses}
          control={
            <TextField
              disabled
              fullWidth
              value={user.email}
              variant="filled"
              InputProps={{ className: textFieldClasses.textField }}
            />
          }
          label={"EMAIL"}
          labelPlacement="top"
        />
        <FormControlLabel
          classes={inputClasses}
          control={
            <TextField
              disabled
              fullWidth
              value={user.phoneNumber ?? ""}
              variant="filled"
              InputProps={{ className: textFieldClasses.textField }}
            />
          }
          label={"PHONE NUMBER"}
          labelPlacement="top"
        />
        <div>
          <FormControlLabel
            classes={inputClasses}
            className={classes.leftInput}
            control={
              <TextField
                disabled
                fullWidth
                value={user.department ?? ""}
                variant="filled"
                InputProps={{ className: textFieldClasses.textField }}
              />
            }
            label={"DEPARTMENT"}
            labelPlacement="top"
          />
          <FormControlLabel
            classes={inputClasses}
            className={classes.rightInput}
            control={
              <TextField
                disabled
                fullWidth
                value={user.team ?? ""}
                variant="filled"
                InputProps={{ className: textFieldClasses.textField }}
              />
            }
            label={"TEAM"}
            labelPlacement="top"
          />
        </div>
        <FormControlLabel
          classes={inputClasses}
          control={
            <TextField
              disabled
              fullWidth
              value={user.role ?? ""}
              variant="filled"
              InputProps={{ className: textFieldClasses.textField }}
            />
          }
          label={"ROLE"}
          labelPlacement="top"
        />
      </div>
      <div className={classes.button}>
        <Button
          className={buttonClasses.primaryButton}
          type="submit"
          onClick={handleNextStep}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default EmployeeStepTwo;
